import { useMediaQuery, useTheme } from "@mui/material";

interface UseCommonThemePropsReturn {
    xlScreen: boolean;
    lgScreen: boolean;
    mobileScreen: boolean;
    navbarHeightPadding: number;
}

const TOP_NAVBAR_HEIGHT_PADDING = 56;

export const useCommonThemeProps = (): UseCommonThemePropsReturn => {
    const theme = useTheme();
    const xlScreen = useMediaQuery(theme.breakpoints.up("xl"));
    const lgScreen = useMediaQuery(theme.breakpoints.up("lg"));
    const mobileScreen = useMediaQuery(theme.breakpoints.down("sm"));

    return {
        xlScreen,
        mobileScreen,
        lgScreen,
        navbarHeightPadding: TOP_NAVBAR_HEIGHT_PADDING,
    };
};
