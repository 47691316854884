import { SESSION_ID_LOCAL_STORAGE_KEY } from "./../constants/token";
import { sentryService } from "src/service/sentry";
import { ApplicationRoute } from "src/shared/types/route";
import { loginWithTokensAndDealId } from "./../../service/auth/api";
import { useBoolean } from "src/shared/hooks/useBoolean";
import { useEffect, useCallback, useState } from "react";
import { useLocation } from "react-router-dom";
import { LoginStatus } from "../types/user";
import { REFRESH_TOKEN_LOCAL_STORAGE_KEY } from "../constants/token";
import { Role, RolePrivileges, rolePrivileges } from "@miraclapp/mortgaging-shared";
import { parseSearchParams } from "../util/route";
import { User } from "firebase/auth";
import { useNavigator } from "./useNavigator";

const CUSTOMER_TOKEN_LOGIN_PAGES: ApplicationRoute[] = [
    ApplicationRoute.CONSULTATION,
    ApplicationRoute.HUB,
    ApplicationRoute.ADDITIONAL_INFO,
];

const CUSTOMER_PAGES: ApplicationRoute[] = [...CUSTOMER_TOKEN_LOGIN_PAGES, ApplicationRoute.SURVEY];

const pathnameMatchesRoutes = (pathname: string, routes: ApplicationRoute[]): boolean =>
    Boolean(routes.find((cp) => pathname?.includes(cp)));

export const useAuthenticateBySessionToken = (
    applicationUser: User,
    setRolePrivileges: (value: RolePrivileges) => void,
    setSessionToken: (token: string) => void,
) => {
    const { navigate } = useNavigator();
    const { search, pathname } = useLocation();
    const [checkedLoginToken, { setValue: setCheckedLoginToken }] = useBoolean(false);
    const [tokenLoginStatus, setTokenLoginStatus] = useState<LoginStatus>(LoginStatus.LOGGED_OUT);
    const [loginViaTokenError, setLoginViaTokenError] = useState<boolean>(false);

    const navigateToApp = useCallback(
        (route: ApplicationRoute, search?: object) => {
            setTokenLoginStatus(LoginStatus.SUCCESS);
            navigate(route, { search, preserveParams: true });
        },
        [setTokenLoginStatus, navigate],
    );

    const checkLoginViaTokens = useCallback(async () => {
        const searchParams = parseSearchParams(search);
        const { dealId, loginToken } = searchParams;
        const isConsultationPath = pathname?.includes(ApplicationRoute.CONSULTATION);
        const isHubPath = pathname?.includes(ApplicationRoute.HUB);
        const isSurveyPath = pathname?.includes(ApplicationRoute.SURVEY);
        const isAdditionalInfoPath = pathname?.includes(ApplicationRoute.ADDITIONAL_INFO);
        try {
            /* if non-customer just opened the app on one of the CUSTOMER_TOKEN_LOGIN_PAGES pages
             if it's a customer, he/she probably did it via the magic link or has the session token
             this checks if that is the case or not */
            if (
                pathnameMatchesRoutes(pathname, CUSTOMER_TOKEN_LOGIN_PAGES) &&
                !localStorage.getItem(SESSION_ID_LOCAL_STORAGE_KEY) &&
                !loginToken
            ) {
                return;
            }

            // if it's survey, just go through (now there's no auth necessary)
            if (isSurveyPath) {
                navigateToApp(ApplicationRoute.SURVEY);
                return;
            } else if (isAdditionalInfoPath) {
                navigateToApp(ApplicationRoute.ADDITIONAL_INFO);
                return;
            }

            const loginResponse = await loginWithTokensAndDealId(
                applicationUser,
                dealId as string,
                loginToken as string,
            );

            if (loginResponse?.status === 200 && loginResponse?.data?.data) {
                const { sessionId, refreshToken, role } = loginResponse.data.data;
                // if this is a login attemptm
                if (refreshToken) {
                    localStorage.setItem(REFRESH_TOKEN_LOCAL_STORAGE_KEY, String(refreshToken));
                }
                if (sessionId) {
                    localStorage.setItem(SESSION_ID_LOCAL_STORAGE_KEY, String(sessionId));
                    setSessionToken(`${sessionId}`);
                }

                setRolePrivileges({
                    role,
                    privileges: rolePrivileges[role],
                });

                switch (role) {
                    case Role.CUSTOMER: {
                        if (isConsultationPath) {
                            navigateToApp(ApplicationRoute.CONSULTATION, { dealId });
                        } else if (isHubPath) {
                            navigateToApp(ApplicationRoute.HUB, { dealId });
                        }
                        return;
                    }
                }
            }
            setLoginViaTokenError(false);
        } catch (error) {
            setLoginViaTokenError(true);
            sentryService.report(error);
        }
    }, [search, pathname, navigateToApp, setSessionToken, applicationUser, setRolePrivileges, setLoginViaTokenError]);

    useEffect(() => {
        if (!checkedLoginToken && pathnameMatchesRoutes(pathname, CUSTOMER_PAGES)) {
            checkLoginViaTokens();
            setCheckedLoginToken(true);
        }
    }, [checkedLoginToken, setCheckedLoginToken, pathname, checkLoginViaTokens]);

    return {
        tokenLoginStatus,
        setTokenLoginStatus,
        loginViaTokenError,
        setLoginViaTokenError,
    };
};
