import {
    ApiResponse,
    DealProperties,
    ExportMode,
    HubspotDeal,
    UpdateDealData,
    PartialDealPropertiesWithOrigin,
    RequiredDocumentProperties,
    SetDealOptionsBody,
    DocumentPropertiesById,
    DocumentPropertiesByType,
    DocumentPropertiesByValue,
    CreateDealRequestBody,
    GetDealsQuery,
    DealPropertiesTask,
    Specialist,
    GetDealsResponseData,
    DealOptions,
} from "@miraclapp/mortgaging-shared";
import { AxiosResponse } from "axios";
import { User } from "firebase/auth";
import { config as appConfig } from "../../config";
import { axiosApiInstance } from "../api/axiosApiInstance";
import { buildInternalRequestConfig } from "../api/helpers";
import { BankIdentifierProtocol } from "../mortgage-engine/types";
import { sentryService } from "../sentry";
import { RequiredDocuments } from "src/shared/types/hub";

/**
 * @todo - refactor - split documents api and deal api
 */

const base = `${appConfig.server.url}/backoffice`;
const dealBase = `${base}/deals`;
const hubspotWorkflowsBase = `${appConfig.server.url}/workflows`;
const workflowTaskBaseUrl = `${appConfig.server.url}/workflows/tasks`;

// used for long-running requests (dealBase times out after 1m)
const internalDealBase = `${appConfig.server.internalUrl}/backoffice/deals`;

export type CreateHubspotTask = ApiResponse<void>;
export type CreateHubspotTaskResponse = AxiosResponse<CreateHubspotTask>;
export const createHubSpotTask = async (
    currentUser,
    /* Assignee of the task */
    ownerId: number,
    /* Associate task with deal */
    dealId: number,
    title: string = "New Task",
    note: string = "",
    timestamp?: number,
): Promise<CreateHubspotTaskResponse> => {
    const config = await buildInternalRequestConfig(currentUser);

    return axiosApiInstance.post<CreateHubspotTask>(
        `${hubspotWorkflowsBase}/tasks`,
        {
            ownerId,
            dealId,
            timestamp,
            title,
            note,
        },
        config,
    );
};

export type GetDealData = {
    deal: DealProperties;
    dealData: DealProperties;
};
export type GetDeal = ApiResponse<GetDealData>;
export type GetDealResponse = AxiosResponse<GetDeal>;
export const getDeal = async (currentUser: User, dealId: string): Promise<GetDealResponse> => {
    const config = await buildInternalRequestConfig(currentUser);
    return axiosApiInstance.get<GetDeal>(`${dealBase}/${dealId}`, config);
};

export const exportDeal = async (
    currentUser: User,
    dealId: string,
    bankId: BankIdentifierProtocol,
    mode: ExportMode,
    taskId: string,
): Promise<AxiosResponse> => {
    const config = await buildInternalRequestConfig(currentUser);
    return axiosApiInstance.get<string>(
        `${internalDealBase}/${dealId}/export?mode=${mode}&taskId=${taskId}&bankId=${bankId}`,
        {
            ...config,
        },
    );
};
export type GetContactDealData = {
    deal: DealProperties;
    hubspotDeal: HubspotDeal;
};
export type GetContactDeal = ApiResponse<GetContactDealData>;
export type GetContactDealResponse = AxiosResponse<GetContactDeal>;
export const getContactDeal = async (currentUser: User, contactId: string): Promise<GetContactDealResponse> => {
    const config = await buildInternalRequestConfig(currentUser);
    return axiosApiInstance.get<GetContactDeal>(`${base}/contact/${contactId}/deal`, config);
};

export type UpdateDealResponseData = {
    dealId: number;
    isDeleted: boolean;
    portalId: number;
    properties: DealProperties;
};
export type UpdateDeal = ApiResponse<UpdateDealResponseData>;
export type UpdateDealNotes = ApiResponse<UpdateDealResponseData>;
export type UpdateDealNotesResponse = AxiosResponse<UpdateDealNotes>;
export const updateDealNotes = async (
    currentUser: User,
    dealId: string,
    data: Pick<DealProperties, "dealNotes">,
): Promise<UpdateDealNotesResponse> => {
    const config = await buildInternalRequestConfig(currentUser);
    return axiosApiInstance.put<UpdateDealNotes>(`${dealBase}/${dealId}/notes`, data, config);
};

export type UpdateChecklistResponseData = {
    requiredCustomerDocuments: string;
    requiredCustomerDocumentsDetails: string;
    hubMagicLink: string;
};
export type UpdateDealChecklist = ApiResponse<UpdateChecklistResponseData>;
export type UpdateDealChecklistResponse = AxiosResponse<UpdateDealChecklist>;
export const updateDealRequiredDocumentsChecklist = async (
    currentUser: User,
    dealId: string,
    data: Pick<DealProperties, "requiredCustomerDocuments" | "requiredCustomerDocumentsDetails">,
): Promise<UpdateDealChecklistResponse> => {
    const config = await buildInternalRequestConfig(currentUser);
    return axiosApiInstance.put<UpdateDealChecklist>(`${dealBase}/${dealId}/checklist`, data, config);
};

export type BackupDealProperties = ApiResponse<never>;
export type BackupDealPropertiesResponse = AxiosResponse<BackupDealProperties>;
export const backupDealProperties = async (
    currentUser: User,
    dealId: string,
    data: Partial<DealProperties>,
): Promise<BackupDealPropertiesResponse | undefined> => {
    const config = await buildInternalRequestConfig(currentUser);
    return await axiosApiInstance.post<BackupDealProperties>(`${dealBase}/${dealId}/backup`, data, config);
};
export type GetDealEntries = {
    items: DealProperties[];
    count: number;
};
export type GetDealEntriesApiResponse = ApiResponse<GetDealEntries>;
export type GetDealEntriesResponse = AxiosResponse<GetDealEntriesApiResponse>;
export const getDealEntries = async (
    currentUser: User,
    dealId: string,
    pageSize = 100,
    page = 1,
    withCount = false,
): Promise<GetDealEntriesResponse | undefined> => {
    const config = await buildInternalRequestConfig(currentUser);
    return await axiosApiInstance.get<GetDealEntriesApiResponse>(
        `${dealBase}/${dealId}/data/details?pageSize=${pageSize}&page=${page}${withCount ? "&withCount=true" : ""}`,
        config,
    );
};

export type GetDealMagicLinksData = {
    topOfferMagicLink: string;
    bookAppointmentLink: string;
    additionalInfoLink: string;
    hubMagicLink: string;
};
export type GetDealMagicLinks = ApiResponse<GetDealMagicLinksData>;
export type GetDealMagicLinksResponse = AxiosResponse<GetDealMagicLinks>;
export const getDealMagicLinks = async (currentUser: User, dealId: string): Promise<GetDealMagicLinksResponse> => {
    const config = await buildInternalRequestConfig(currentUser);
    return axiosApiInstance.get<GetDealMagicLinks>(`${dealBase}/${dealId}/magiclinks`, config);
};

export type SyncDealProperties = ApiResponse<never>;
export type SyncDealPropertiesResponse = AxiosResponse<BackupDealProperties>;
export const syncDealProperties = async (
    currentUser: User,
    dealId: string,
    data: PartialDealPropertiesWithOrigin,
    backup: boolean = true,
): Promise<{
    sync: SyncDealPropertiesResponse;
    backup: BackupDealPropertiesResponse | undefined;
}> => {
    const config = await buildInternalRequestConfig(currentUser);

    /**
     * MOR-470: we don't want to send dealNotes and required documents with a general deal data update
     * Also, it's safer to destructure the unnecessary fields
     * and retrieve only the necessary fields into a sub-object using the spread syntax
     */
    const { dealNotes, requiredCustomerDocuments, requiredCustomerDocumentsDetails, ...necessaryData } = data;
    necessaryData.savedBy = currentUser?.email || `${data.firstNameBorrower1} ${data.lastNameBorrower1}`;

    /**
     * Client side safe guard against potential update of some deal where
     * payload deal id is not the same as the deal id of the update request
     *
     * We explictly check if the payload ID is defined first since we can send partial updates as well
     * and in that case payload maybe does not have the id property, rather only one property to be updated
     */
    if (necessaryData.id && String(necessaryData.id) !== String(dealId)) {
        sentryService.setContext("SyncDealProperties", { dealId, payloadId: necessaryData.id });
        sentryService.report("[syncDealProperties API] Payload ID does not match deal ID");
    }

    const [syncResponse, backupResponse] = await Promise.allSettled([
        axiosApiInstance.put<SyncDealProperties>(`${dealBase}/${dealId}`, necessaryData, config),
        backup ? backupDealProperties(currentUser, dealId, data) : undefined,
    ]);

    return {
        sync: syncResponse.status === "fulfilled" ? syncResponse.value : syncResponse.reason,
        backup: backupResponse.status === "fulfilled" ? backupResponse.value : backupResponse.reason,
    };
};

export type ArchiveDeal = ApiResponse<HubspotDeal>;
export type ArchiveDealResponse = AxiosResponse<ArchiveDeal>;
export const archiveDeal = async (
    currentUser: User,
    dealId: string,
    reasons: Pick<DealProperties, "closedLostReasonSingle" | "closedLostReasonOther">,
): Promise<ArchiveDealResponse> => {
    const config = await buildInternalRequestConfig(currentUser);
    return axiosApiInstance.put<ArchiveDeal>(`${dealBase}/${dealId}/archive`, reasons, config);
};

export const triggerBankingWalkthroughWorkflow = async (currentUser: User, dealId: string): Promise<AxiosResponse> => {
    const config = await buildInternalRequestConfig(currentUser);
    return axiosApiInstance.patch(`${workflowTaskBaseUrl}/bank/export/walkthrough/${dealId}`, {}, config);
};

export type GetDealPropertiesBackup = ApiResponse<DealProperties>;
export type GetDealPropertiesBackupResponse = AxiosResponse<GetDealPropertiesBackup>;
export const getDealPropertiesBackup = async (
    currentUser: User,
    dealId: string,
): Promise<GetDealPropertiesBackupResponse> => {
    const config = await buildInternalRequestConfig(currentUser);
    return axiosApiInstance.get<GetDealPropertiesBackup>(`${dealBase}/${dealId}/backup`, config);
};

export type SetDealOptions = ApiResponse;
export type SetDealOptionsResponse = AxiosResponse<SetDealOptions>;
export const setDealOptions = async (currentUser: User, dealId: string, data: SetDealOptionsBody) => {
    const config = await buildInternalRequestConfig(currentUser);
    return axiosApiInstance.post<SetDealOptions>(`${dealBase}/${dealId}/options`, data, config);
};

export type GetDealOptionsData = DealOptions;
export type GetDealOptions = ApiResponse<GetDealOptionsData>;
export type GetDealOptionsResponse = AxiosResponse<GetDealOptions>;
export const getDealOptions = async (currentUser: User, dealId: string): Promise<GetDealOptionsResponse> => {
    const config = await buildInternalRequestConfig(currentUser);
    return axiosApiInstance.get<GetDealOptions>(`${dealBase}/${dealId}/options`, config);
};

export type LogMilestoneResponseData = {
    dealId: number;
    isDeleted: boolean;
    portalId: number;
    properties: DealProperties;
};
export type LogMilestone = ApiResponse<LogMilestoneResponseData>;
export type LogMilestoneResponse = AxiosResponse<LogMilestone>;
export const logMilestone = async (
    currentUser: User,
    dealId: string,
    data: UpdateDealData,
): Promise<LogMilestoneResponse> => {
    const config = await buildInternalRequestConfig(currentUser);
    return axiosApiInstance.put<UpdateDeal>(`${dealBase}/${dealId}/milestone`, data, config);
};

export type RequiredDocumentsResponseData = {
    required: RequiredDocumentProperties[];
    propertiesById: DocumentPropertiesById;
    propertiesByType: DocumentPropertiesByType;
    propertiesByValue: DocumentPropertiesByValue;
    documents: RequiredDocuments;
    createdAt: number;
};

export type GetRequiredDocuments = ApiResponse<RequiredDocumentsResponseData>;
export type GetRequiredDocumentsResponse = AxiosResponse<GetRequiredDocuments>;
export const getRequiredDocuments = async (
    currentUser: User,
    dealId: string,
): Promise<GetRequiredDocumentsResponse> => {
    const config = await buildInternalRequestConfig(currentUser);
    return axiosApiInstance.get<GetRequiredDocuments>(`${dealBase}/${dealId}/documents/required`, config);
};

export type GetDealDoc = DealProperties & {
    tasks?: DealPropertiesTask[];
    owner?: Specialist;
};

export type GetDeals = ApiResponse<GetDealsResponseData>;
export type GetDealsResponse = AxiosResponse<GetDeals>;
export const getDeals = async (currentUser: User, query: GetDealsQuery): Promise<GetDealsResponse> => {
    const config = await buildInternalRequestConfig(currentUser);
    return axiosApiInstance.get<GetDeals>(dealBase, { ...config, params: query });
};

export type PatchDeal = ApiResponse<DealProperties>;
export type PatchDealResponse = AxiosResponse<PatchDeal>;
export const patchDeal = async (
    currentUser: User,
    dealId: string,
    payload: Partial<DealProperties>,
): Promise<PatchDealResponse> => {
    const config = await buildInternalRequestConfig(currentUser);
    return axiosApiInstance.patch<PatchDeal>(`${dealBase}/${dealId}`, payload, config);
};

export type CreateDeal = ApiResponse<DealProperties>;
export type CreateDealResponse = AxiosResponse<CreateDeal>;
export const createDeal = async (currentUser: User, body: CreateDealRequestBody): Promise<CreateDealResponse> => {
    const config = await buildInternalRequestConfig(currentUser);
    return axiosApiInstance.post<CreateDeal>(dealBase, body, config);
};

export type CheckEmailResponseData = {
    emailAlreadyInUse: boolean;
};
export type CheckEmail = ApiResponse<CheckEmailResponseData>;
export type CheckEmailResponse = AxiosResponse<CheckEmail>;
export const checkEmail = async (currentUser: User, email: string): Promise<CheckEmailResponse> => {
    const config = await buildInternalRequestConfig(currentUser);
    return axiosApiInstance.get<CheckEmail>(`${dealBase}/check-email?email=${email}`, config);
};

export type CreateHubNoteRequestPayload = {
    note: string;
};
export type HubNote = {
    note: string;
    createdAt: number;
};
export type CreateHubNoteResponse = AxiosResponse<HubNote>;
export const createHubNote = async (currentUser: User, dealId: string, payload: CreateHubNoteRequestPayload) => {
    const config = await buildInternalRequestConfig(currentUser);
    return axiosApiInstance.post<PatchDeal>(`${dealBase}/${dealId}/hub-notes`, payload, config);
};
