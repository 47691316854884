export enum AccountDetails {
    RAIFFEISEN = "Raiffeisen",
    BANK_AUSTRIA = "Bank Austria",
    ERSTE_BANK = "Erste Bank",
    BAWAG = "BAWAG",
    HYPO = "Hypo",
    EASY_BANK = "Easy Bank",
    OBERBANK = "Oberbank",
    SONSTIGES = "Sonstiges",
}
