import { User } from "firebase/auth";
import { config } from "../../config";
import { axiosApiInstance } from "../api/axiosApiInstance";
import { buildInternalRequestConfig } from "../api/helpers";
import { Specialist } from "@miraclapp/mortgaging-shared";

const base = `${config.server.url}`;

export const getUsers = async (currentUser: User): Promise<{ data: Specialist[] }> => {
    const config = await buildInternalRequestConfig(currentUser);
    const response = await axiosApiInstance.get<Buffer>(`${base}/users`, config);
    return response.data as unknown as { data: Specialist[] };
};

export const postUser = async (currentUser: User, payload: Specialist) => {
    const config = await buildInternalRequestConfig(currentUser);
    const response = await axiosApiInstance.post<Buffer[]>(`${base}/users`, payload, config);
    return response.data;
};

export const canUserSignUp = async (email: string): Promise<{ success: boolean }> => {
    const response = await axiosApiInstance.get<Specialist>(`${base}/backoffice/validations/signup/${email}`);
    return response.data as unknown as { success: boolean };
};
